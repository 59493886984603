import { configureStore } from '@reduxjs/toolkit';
import app from '../App/ducks';
import settings from '../Settings/ducks';
import heartbeat from '../Heartbeat/ducks';
import { jwtMiddleware, jwtListener, jwtReducer } from './jwtMiddleware';

const initializeStore = () => {
  const rootReducer = {
    settings,
    app,
    heartbeat,
    token: jwtReducer,
  };

  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [jwtMiddleware].concat(getDefaultMiddleware()),
  });

  store.subscribe(() => jwtListener(store.getState()));

  return store;
};

export default initializeStore;
